import Layout from "components/Layout";
import Page from "components/Page";
import SEO from "components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

function ContactPage() {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      allContentfulPageContact {
        edges {
          node {
            pageTitle
            heroSectionHeading
            bodySectionContent {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageContact.edges[0].node;
  return (
    <Layout>
      <SEO
        // metaDescription={dataNode.pageDescription}
        title={dataNode.pageTitle}
      />
      <Page contact data={dataNode} />
    </Layout>
  );
}

export default ContactPage;
